import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/index',
    component: layout,
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('@/views/index/index.vue')
      }
    ]

  },
  {
    path: '/job',
    component: layout,
    redirect:'/job/jobList',
    children: [
      {
        path: 'jobList',
        name: 'jobList',
        component: () => import('@/views/jobList/index.vue')
      },
      {
        path: 'jobDetail',
        name: 'jobDetail',
        component: () => import('@/views/jobDetail/index.vue'),
        meta: {
          activeMenu: '/job/jobList'
        }
      }
    ]
  },
  // {
  //   path: '/',
  //   component: layout,
  //   children: [
  //     {
  //       path: 'jobDetail',
  //       name: 'jobDetail',
  //       component: () => import('@/views/jobDetail/index.vue')
  //     }
  //   ]
  // }

]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;

  // 兼容IE
  window.scrollTo(0, 0);
  next();
})
export default router
