<template>
  <div class="app-main">
    <div class="fixed-header box-container">
      <el-row>
        <el-col :span="12">
          <div class="address-box">
            <i class="el-icon-location-outline fc-main"></i>
            <span class="fc-main fz-18">温州</span>
          </div>
        </el-col>
        <el-col :span="12" style="display: flex;justify-content: flex-end;">
          <el-menu router class="el-menu-demo" mode="horizontal" :text-color="'#333'" :active-text-color="'#5E80FF'"
            :default-active="onRoutes">
            <el-menu-item v-for="(item, index) in navbarList" :index="item.path">{{ item.title }}</el-menu-item>
          </el-menu>
        </el-col>
      </el-row>
    </div>
    <div class="main-box">
      <router-view />
    </div>
    <div class="footer box-container">
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <h4>联系信东家</h4>
          <p>公司：温州好东家科技有限公司</p>
          <p>邮箱：240640741@qq.com</p>
          <p>地址：温州市双屿街道正岙路总部经济基地A07</p>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <h4>信东家</h4>
          <p>联系方式：130-6458-8598</p>
          <p>服务时间：周一至周五9:00-18:00</p>
          <p><a href="https://beian.miit.gov.cn/#/Integrated/index">浙ICP备20026009号-2</a></p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'index',
  components: {},
  props: [''],
  data() {
    return {
      activeIndex: '0',
      navbarList: [
        { title: '首页', path: '/index' },
        { title: '职位', path: '/job/jobList' }
      ]
    };
  },
  watch: {},
  created() { },
  beforeMount() { },
  mounted() { },
  methods: {
  },
  computed: {
    onRoutes() {
      const route = this.$route
      const { meta, path } = route
      // 在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
      // meta中有activeMenu 字段的子页面 都会显示高亮
      console.log(meta)
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    }
  }
}
</script>
<style lang='scss' scoped>
@import '@/style/variables.scss';

.app-main {
  height: 100%;
}

.fixed-header ::v-deep {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: #ffffff;
  color: #ffffff;
  z-index: 999;

  .address-box {
    height: 40px;
    line-height: 40px;
  }

  .address-box i {
    margin-right: 4px;
  }

  .el-menu {
    background-color: transparent;
  }

  .el-menu.el-menu--horizontal {
    border: none;
  }

  .el-menu--horizontal>.el-menu-item {
    height: 40px;
    line-height: 40px;
    border: none;
  }

  .el-menu--horizontal>.el-menu-item.is-active {
    border: none;
    // color: $font_color !important;
  }

}

.main-box {
  margin-top: 40px;
  min-height: calc(100% - 180px);
}

.footer {
  min-height: 180px;
  background: #313438;

  h4 {
    color: rgba(255, 255, 255, .8);
  }

  p {
    color: rgba(255, 255, 255, .5);
    font-size: .75rem;
  }
}
</style>